<template>
  <div>
    <div class="row mb-5">
      <div class="col-12 mb-4">
        <button v-on:click="showAdd" class="btn btn-outline-primary">
          Create New Product Group
        </button>
      </div>
      <div class="white-box">
        <div v-for="item in items" :key="item.id" class="col-12 row item">
          <div class="col">
            <router-link :to="'/products/related/' + item.id">
              {{ item.group_name }}
            </router-link>
          </div>
          <div class="col-3">
            <EditOnClick
              v-model="item.product_category_id"
              type="category"
              :values="categories"
              v-on:update="
                (e) => saveCategoryData(e, 'product_category_id', item.id)
              "
            />
          </div>
          <div class="col-auto text-end">
            <router-link
              :to="'/products/related/' + item.id"
              class="btn btn-outline-light btn-sm text-black"
            >
              <BaseIcon name="add" />
              <span class="text-black regular-12">
                {{ $t("nested.newSubcategory") }}
              </span>
            </router-link>
            <button
              class="btn btn-outline-light btn-sm"
              v-on:click="showEditGroup(item)"
            >
              <BaseIcon name="edit" />
              <span class="text-black regular-12">
                {{ $t("editOnClick.edit") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewProductGroup ref="newProductGroupModal" />
    <CategoryModal
      ref="addCategoryModal"
      v-model:active-languages="activeLanguages"
      v-model:selected-language="selectedLanguage"
      :edit-category="true"
      type="group"
      v-on:saveCategory="saveGroup"
    />
  </div>
</template>
<script>
import http from "../../modules/http";
import { useStore } from "vuex";
import NewProductGroup from "../../components/modals/NewProductGroup.vue";
import { defineAsyncComponent } from "vue";
import CategoryModal from "../../components/modals/CategoryModal.vue";
import EditOnClick from "../../components/inputs/EditOnClick.vue";

const BaseIcon = defineAsyncComponent(() =>
  import("../../components/icons/BaseIcon.vue")
);

export default {
  name: "RelatedProductGroups",
  components: { CategoryModal, NewProductGroup, BaseIcon, EditOnClick },
  data() {
    return {
      store: useStore(),
      firstLoad: true,
      tableKey: 0,
      addPartner: false,
      rows: [],
      categories: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      selectedItems: [],
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      statuses: [],
      order: {
        row: null,
        desc: true,
      },
      selectedLanguage: "en",
      editCategory: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    filters() {
      return this.store.state.filters?.relatedProductGroups;
    },
    q() {
      return this.store.state.topBar.q;
    },
    activeLanguages() {
      return this.store.state.languages;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "relatedProductGroups");
    this.loadData();
  },
  methods: {
    loadMore() {
      this.page++;
      this.loadData();
    },
    showAdd() {
      this.$refs.newProductGroupModal.showModal();
    },
    loadData() {
      this.firstLoad = false;

      http.fetch(`/products/products/categories?language=en`).then((data) => {
        this.categories = [];
        for (const category of data.data) {
          this.categories.push({
            name: `${category.name}`,
            top: category.name,
            sub: "",
            id: category.id,
          });
        }
      });

      http.fetch("/products/related").then((data) => {
        this.items = data;
      });
    },
    showEditGroup(item) {
      this.editCategory = item;
      this.$refs.addCategoryModal.addCategory = item;
      this.$refs.addCategoryModal.showModal();
    },
    saveGroup(data) {
      http
        .fetch("/products/related/" + this.editCategory.id + "/edit", data)
        .then(() => {
          this.$refs.addCategoryModal.hideModal();
        });
    },
    saveCategoryData(val, row, id) {
      let data = {};
      if (row === "product_category_id") {
        data[row] = val.id;
      } else {
        data[row] = val;
      }
      http.fetch("/products/related/" + id + "/edit", data);
    },
  },
};
</script>
