<template>
  <div ref="summernote" v-html="value"></div>
</template>

<script>
export default {
  name: "WYSIWYG",
  data() {
    return {
      value: this.modelValue,
      settings: {
        dialogsInBody: true,
        dialogsFade: false,
        codeviewFilter: false,
        codeviewIframeFilter: true,
        styleTags: ["p", "blockquote", "pre", "h2", "h3", "h4", "h5", "h6"],
        toolbar: [
          ["style", ["style", "bold", "italic", "strikethrough"]],
          ["font", ["superscript", "subscript"]],
          //["fontsize", ["fontsize", "height"]],
          ["color", ["forecolor"]],
          ["para", ["ul", "ol", "table", "paragraph"]],
          ["", ["link", "picture", "video"]],
          ["", ["undo", "redo", "clear", "codeview"]],
        ],
        cleaner: {
          action: 'both',
          icon: '<i class="note-icon">Clean</i>',
          keepHtml: true,
          keepTagContents: [
            "span",
            "div",
            "article",
            "nav",
            "section",
            "button",
            "label",
          ],
          badTags: [
            "svg",
            "path",
            "g",
            "circle",
            "select",
            "option",
            "textarea",
            "input",
            "applet",
            "col",
            "colgroup",
            "embed",
            "noframes",
            "noscript",
            "script",
            "style",
            "title",
            "br",
          ],
          badAttributes: [
            "bgcolor",
            "border",
            "height",
            "cellpadding",
            "cellspacing",
            "lang",
            "start",
            "style",
            "class",
            "id",
            "valign",
            "width",
            "aria-current",
            "aria-role",
          ],
          limitChars: false,
          limitDisplay: "both",
          limitStop: false,
          notTimeOut: 850,
          imagePlaceholder: "https://via.placeholder.com/200",
        },
        popover: {
          table: [
            ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
            ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
          ],
        },
        hint: this.hints,
      },
    };
  },
  props: {
    modelValue: Object,
    hints: Object,
  },
  emits: ["update:modelValue", "change"],
  mounted() {
    this.value = this.modelValue;
    const _this = this;
    // eslint-disable-next-line no-undef
    $(this.$refs.summernote).summernote(this.settings);
    // eslint-disable-next-line no-undef
    $(this.$refs.summernote).on("summernote.change", function () {
      //eslint-disable-next-line no-undef
      const val = $(_this.$refs.summernote).summernote("code");
      _this.$emit("update:modelValue", val);
      _this.$emit("change");
    });
  },
};
</script>
