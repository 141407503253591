<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New product group</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-start m-2 custom-input">
            <div class="row">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray"> Name </label>
                <input class="form-control" v-model="group.name" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  Main category
                </label>
                <v-select
                  :options="categories"
                  v-model="group.main_category"
                  label="name"
                  placeholder="Search categories"
                  :reduce="(c) => c.id"
                  append-to-body
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  Pair categories
                </label>
                <v-select
                  :options="categories"
                  v-model="group.categories"
                  label="name"
                  multiple
                  placeholder="Search categories"
                  :reduce="(c) => c.id"
                  append-to-body
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addGroup"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "NewProductGroup",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      group: {},
      categories: [],
    };
  },
  methods: {
    setUpCategories(data, str = "") {
      if (data.categories !== undefined) {
        for (const category of data.categories) {
          this.setUpCategories(
            category,
            data.name !== undefined ? `${str}${data.name} - ` : ""
          );
        }
      } else {
        this.categories.push({
          name: `${str}${data.name}`,
          top: data.name,
          sub: str,
          id: data.id,
        });
      }
    },
    load() {
      http.fetch(`/products/products/categories?language=en`).then((data) => {
        this.categories = [];
        this.setUpCategories({ categories: data.data });
      });
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.load();
    },
    addGroup() {
      this.loadAddBtn = true;
      http
        .fetch("/products/related", this.group, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.myModal.hide();
          this.$emit("add", data);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: {},
};
</script>
